import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useToggle } from 'hooks';
import { Accordion, Switch } from 'components/UI';
import { OptionType } from './UI/OptionType';
import { useMasterData } from 'hooks';
import { MarketplaceTypeExotics } from '@spike/marketplace-model';
import { AddOther } from './UI/AddOther';
import { lgPixel } from 'utils/VariableUtils';
import { Category } from './UI/Category';
import { OrderedOption } from '@spike/model';
import { PetTypeStatusState } from 'reducers/businessSettings/PetTypeStatusState';
import { useCommonStyles } from './UI/commonStyles';

export interface Props {
    id?: string;
    exoticsType: MarketplaceTypeExotics;
    onChange: (exoticPetType: MarketplaceTypeExotics) => void;
    petFeatures?: PetTypeStatusState;
    className?: string;
}

const fontSize = 16;
const top = 55;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordionTitle: {
            'borderBottom': '1px solid #EFEFEF',
            'padding': 0,
            [theme.breakpoints.down('md')]: {
                borderBottom: 'none'
            },
            '& .MuiAccordionSummary-content': {
                fontSize: '16px',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                    fontSize: '6px',
                    marginTop: '28px',
                    paddingBottom: '20px',
                    width: '100%',
                    marginBottom: '0px'
                }
            },

            '&.MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit',
                transform: 'rotate(0deg)',
                [theme.breakpoints.down('sm')]: {
                    borderBottom: '1px solid #D4D4D4',
                    paddingBottom: '3px',
                    display: 'flex',
                    flexDirection: 'row'
                }
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                [theme.breakpoints.down('md')]: {
                    margin: '4px 0',
                    marginBottom: '0px',
                    marginTop: '28px',
                    paddingBottom: '20px'
                },
                [theme.breakpoints.only('md')]: {
                    margin: '6px 0'
                },
                [theme.breakpoints.only('lg')]: {
                    margin: '8px 0'
                },
                [theme.breakpoints.only('xl')]: {
                    margin: '12px 0'
                }
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '46px'
            }
        },

        title: {
            fontWeight: 500,
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down('md')]: {
                lineHeight: '16px',
                fontWeight: '500'
            },
            [theme.breakpoints.only('lg')]: {
                lineHeight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                lineHeight: '30px'
            }
        },
        titleSection: {
            color: '#000000',
            fontSize: '16px',
            [theme.breakpoints.down('lg')]: {
                marginBottom: '18px'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '24px'
            }
        },
        checkBox: {
            [theme.breakpoints.down('sm')]: {
                width: '3px'
            },
            [theme.breakpoints.only('md')]: {
                width: '5px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '7px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '11px'
            }
        },
        containerCheck: {
            'display': 'flex',
            'alignItems': 'flex-start',
            'marginTop': '2.5px',
            '& .MuiTypography-root': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: `${fontSize * 0.62}px`
                },
                [theme.breakpoints.only('md')]: {
                    fontSize: `${fontSize * 0.75}px`
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: `${fontSize * 0.66}px`
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: `${fontSize}px`
                }
            }
        },
        textTest: {
            color: '#969696',
            fontWeight: 400,
            fontSize: `16px`,
            [theme.breakpoints.down('sm')]: {
                fontSize: `${14 * 0.62}px`,
                lineHeight: `${30 * 0.62}px`
            }
        },
        inputEdit: {
            'width': '100%',
            'color': '#969696',
            'borderColor': '#CBCBCB',
            'border': '1px solid #222222',
            'fontSize': `16px`,
            [theme.breakpoints.down('sm')]: {
                fontSize: `${14 * 0.62}px`,
                lineHeight: `${30 * 0.62}px`,
                height: `${32 * 0.62}px`
            },
            '&:focus': {
                outline: 'none'
            }
        },
        column: {
            flexBasis: '33.33%'
        },
        contentMobile: {
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'
            }
        },
        content: { justifyContent: 'center' },
        addOtherContainer: {
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                'marginTop': '0px',
                'marginBottom': '15px',
                'top': '0px',
                '& > p': {
                    color: '#92B4A7',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '27px',
                    marginTop: '0px'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'solid 1px #D3D3D3 !important',
                    height: '40px',
                    borderRadius: '25px',
                    marginLeft: '28px',
                    width: '100%'
                },
                '.MuiFormControlLabel-root': {
                    marginLeft: '-20px'
                },
                '& input[type=text]': {
                    paddingTop: '9px',
                    paddingBottom: '10px',
                    paddingLeft: '14px',
                    marginLeft: '10px',
                    width: '100%'
                },
                '& .MuiIconButton-label': {
                    marginLeft: '-13px'
                },
                '& > div': {
                    '& .MuiIconButton-label': {
                        marginLeft: '0px'
                    },
                    '& p': {
                        fontSize: '16px',
                        lineHeight: '1.5',
                        color: '#222',
                        fontWeight: '400'
                    },
                    '& input[type=text]': {
                        marginLeft: '0px'
                    },
                    '& fieldset': {
                        marginLeft: '0px !Important'
                    }
                },
                '& > .MuiInputBase-adornedStart': {
                    'width': 'calc(100% - 30px)',
                    '& .MuiIconButton-label': {
                        marginLeft: '-13px'
                    },
                    '& input': {
                        width: '100%'
                    },
                    '& input[type=text]': {
                        paddingLeft: '16px'
                    },
                    '& fieldset': {
                        width: '100%',
                        marginLeft: '32px !important',
                        paddingLeft: '5px'
                    }
                }
            },
            [theme.breakpoints.only('lg')]: {
                top: lgPixel(top)
            },
            [theme.breakpoints.only('xl')]: {
                top: `${top}px`
            }
        }
    })
);

export const ExoticPetType: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const commonClasses = useCommonStyles();

    const [open, handleOpen] = useToggle(props.exoticsType.enabled);

    const handleCheckAll = () => {
        props.onChange({ ...props.exoticsType, enabled: !open });
        handleOpen();
        /*
        if (!open) {
            props.onChange({
                types: [...masterData.petExoticTypes],
                others: []
            });
        } else {
            props.onChange(undefined);
        }*/
    };

    const changeTypesHanlder = (options: Array<OrderedOption<string>>) => {
        if (props.exoticsType) {
            props.onChange({
                ...props.exoticsType,
                types: [...options].map(e => e.element)
            });
        }
    };

    const handleAddOther = (value: string) => {
        if (props.exoticsType && !props.exoticsType.others.some(other => other === value)) {
            props.onChange({
                ...props.exoticsType,
                others: [...props.exoticsType.others, value]
            });
        }
    };

    const handleEditOther = (value: string, index: number) => {
        if (props.exoticsType) {
            const others = [...props.exoticsType.others];
            others[index] = value;
            props.onChange({ ...props.exoticsType, others });
        }
    };

    const handleCheckOtherChange = (other: string) => {
        if (props.exoticsType) {
            props.onChange({
                ...props.exoticsType,
                others: props.exoticsType.others.filter(o => o !== other)
            });
        }
    };

    return (
        <Accordion
            withoutIcon
            expanded={open}
            handleExpandedChange={handleCheckAll}
            title={'Exotics'}
            titleStyle={classes.title}
            titleContainerStyles={`${classes.accordionTitle}`}
            expandComponent={<Switch id={props.id} checked={open} onChange={handleCheckAll} />}
            contentContainerStyles={commonClasses.container}
        >
            <Category
                title="TYPE"
                masterDataOptions={masterData.petExoticTypes.map((e, index) => ({ element: e, order: index }))}
                options={
                    props.exoticsType?.types.map((e, index) => ({
                        element: e,
                        order: index
                    })) || []
                }
                onChange={changeTypesHanlder}
            />
            <Box className={classes.addOtherContainer}>
                {props.exoticsType !== undefined &&
                    props.exoticsType.others.map((other, index) => {
                        return (
                            <OptionType
                                key={other}
                                index={index}
                                checked={true}
                                optionName={other}
                                editable
                                section="petType"
                                onEditName={nameEdited => handleEditOther(nameEdited, index)}
                                onTypeChange={() => handleCheckOtherChange(other)}
                            />
                        );
                    })}

                <AddOther onAddType={handleAddOther} />
            </Box>
        </Accordion>
    );
};
