import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { AdyenPayment, CompletedInfo } from 'components/Payments/Adyen/AdyenPayment';
import { FunctionComponent, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { Button, Spinner } from 'components/UI';
import isEmpty from 'lodash/isEmpty';

interface CreditCardPaymentProps {
    invoiceId?: number;
    depositInvoiceId?: number;
    className?: string;
    onError?: () => void;
    onSuccess?: (sessionId: string) => void;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(60)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '60px'
            }
        },
        icon: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(70)}px`,
                marginBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '70px',
                marginBottom: '30px'
            }
        },
        successIcon: {
            color: '#00AA00'
        },
        errorIcon: {
            color: '#C14A4A'
        },
        success: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#00AA00',
            width: '100%',
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        error: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#C14A4A',
            width: '100%',
            textAlign: 'center',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        paymentFormContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        },
        backButton: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(100)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '100px'
            }
        },
        spinner: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        }
    })
);

export const CreditCardPayment: FunctionComponent<CreditCardPaymentProps> = props => {
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const completePaymentHandler = (completedInfo: CompletedInfo) => {
        if (completedInfo.success) {
            setSuccessMessage(completedInfo.message);
            props.onSuccess && props.onSuccess(completedInfo.sessionId);
        } else {
            setErrorMessage(completedInfo.message);
        }
    };

    const errorPaymentHandler = (message: string) => {
        setErrorMessage(message);
        props.onError && props.onError();
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            {isEmpty(successMessage) && isEmpty(errorMessage) && (
                <Box className={classes.paymentFormContainer}>
                    <AdyenPayment
                        hideRemovePaymentMethodButton={true}
                        invoiceId={props.invoiceId}
                        depositInvoiceId={props.depositInvoiceId}
                        onComplete={completePaymentHandler}
                        onError={errorPaymentHandler}
                    />
                </Box>
            )}
            {errorMessage !== null && (
                <Box className={classes.messageContainer}>
                    <FontAwesomeIcon icon={faCircleXmark} className={clsx(classes.icon, classes.errorIcon)} />
                    <Typography className={classes.error}>{errorMessage}</Typography>
                    <Button label="Back" color="black" onClick={props.onBack} className={classes.backButton} />
                </Box>
            )}
            {successMessage !== null && (
                <Box className={classes.messageContainer}>
                    <FontAwesomeIcon icon={faCircleCheck} className={clsx(classes.icon, classes.successIcon)} />
                    <Typography className={classes.success}>{successMessage}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default CreditCardPayment;
