import { MarketplacePetSize } from '@spike/marketplace-model';
import { Ordered } from '@spike/model';
import { sortSizes } from 'utils/WeightUtil';

export const validateSizes = (sizes: Array<Ordered<MarketplacePetSize>>) => {
    const sizesInOrder = sortSizes(sizes).map(size => size.element);

    return (
        validateAtLeastOne(sizesInOrder) &&
        validateStartsAtZero(sizesInOrder) &&
        validateMinMax(sizesInOrder) &&
        validateGapsAndOverlaps(sizesInOrder)
    );
};

export const validateAtLeastOne = (sizesInOrder: Array<MarketplacePetSize>) => {
    return sizesInOrder.length > 0;
};

export const validateStartsAtZero = (sizesInOrder: Array<MarketplacePetSize>) => {
    return sizesInOrder.length > 0 && sizesInOrder[0].minWeight === 0;
};

export const validateMinMax = (sizesInOrder: Array<MarketplacePetSize>) => {
    return !sizesInOrder.some(
        size => size.maxWeight !== null && size.minWeight !== null && size.maxWeight < size.minWeight
    );
};

const validateGapsAndOverlaps = (sizesInOrder: Array<MarketplacePetSize>) => {
    return (
        sizesInOrder.reduce((lastMaxWeight: number | null, size, index) => {
            const isFirst = index === 0;
            const isLast = index + 1 === sizesInOrder.length;

            if (isFirst && isLast) {
                return size.minWeight === null || size.minWeight > 0 ? null : size.minWeight;
            } else if (isFirst) {
                return size.maxWeight;
            } else if (lastMaxWeight === null) {
                return null;
            } else if (size.minWeight === null) {
                return null;
            } else if (lastMaxWeight + 1 !== size.minWeight) {
                return null;
            } else {
                return isLast ? size.minWeight : size.maxWeight;
            }
        }, 0) !== null
    );
};
