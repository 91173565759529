import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import {
    getDropAdminInSessionThunk,
    getDropInCampaignSessionThunk,
    getDropInChangePaymentMethodSessionThunk,
    getDropInDepositSessionThunk,
    getDropInPaymenLinkSessionThunk,
    getDropInSessionThunk,
    getDropInSubscriptionSessionThunk,
    removeDropInPaymentMethod
} from '@spike/payments-action';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { DropIn } from '@spike/payments-model';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentsStatus } from '@spike/payments-action';
import { RootState } from 'store';
import { wbp } from 'Theme';
import { adyenResultCodes } from '@spike/adyen-result-codes';
import { useApiClientWrapper } from 'hooks';

/* eslint-disable */
interface AdyenPaymentProps {
    invoiceId?: number;
    adminInvoiceId?: number;
    campaignId?: number;
    subscriptionId?: number;
    depositInvoiceId?: number;
    paymentLinkDropInUrl?: string;
    authToken?: string;
    className?: string;
    hideRemovePaymentMethodButton?: boolean;
    hideStoredPaymentMethods?: boolean;
    actionType?: 'changePaymentMethod';
    onComplete?: (info: CompletedInfo) => void;
    onError?: (message: string) => void;
}

export interface CompletedInfo {
    success: boolean;
    message: string;
    sessionId: string;
    resultCode?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        dropIn: {
            'width': '100%',

            '& > div, & iframe': {
                maxWidth: '100%'
            }
        },
        error: {
            width: '100%',
            textAlign: 'center',
            color: '#EF4F57',
            fontWeight: 500,
            letterSpacing: 2,
            textTransform: 'uppercase',
            [theme.breakpoints.down(wbp)]: {
                fontSize: '16px'
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px'
            }
        }
    })
);

const NETWORK_ERROR = 'NETWORK_ERROR';

export const AdyenPayment: FunctionComponent<AdyenPaymentProps> = props => {
    const classes = useStyles();
    const dropInRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(state => state.payments.status);
    const dropInInfo = useSelector<RootState, DropIn | undefined>(state => state.payments.dropIn);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (props.invoiceId) {
            dispatch(getDropInSessionThunk(apiClientWrapper, props.invoiceId));
        } else if (props.paymentLinkDropInUrl && props.authToken) {
            dispatch(getDropInPaymenLinkSessionThunk(props.authToken, props.paymentLinkDropInUrl));
        } else if (props.adminInvoiceId) {
            dispatch(getDropAdminInSessionThunk(apiClientWrapper, props.adminInvoiceId!));
        } else if (props.campaignId) {
            dispatch(getDropInCampaignSessionThunk(apiClientWrapper, props.campaignId!));
        } else if (props.depositInvoiceId) {
            dispatch(getDropInDepositSessionThunk(apiClientWrapper, props.depositInvoiceId));
        } else if (props.subscriptionId && props.actionType === undefined) {
            dispatch(getDropInSubscriptionSessionThunk(apiClientWrapper, props.subscriptionId));
        } else if (props.subscriptionId && props.actionType === 'changePaymentMethod') {
            dispatch(getDropInChangePaymentMethodSessionThunk(apiClientWrapper, props.subscriptionId));
        }
    }, []);

    useNonInitialEffect(() => {
        if (
            [
                PaymentsStatus.GetDropInSessionSuccess,
                PaymentsStatus.GetDropInAdminSessionSuccess,
                PaymentsStatus.GetDropInCampaignSessionSuccess,
                PaymentsStatus.GetDropInDepositSessionSuccess,
                PaymentsStatus.GetDropInSubscriptionSessionSuccess,
                PaymentsStatus.GetDropInChangePaymentMethodSessionSuccess
            ].includes(paymentsStatus)
        ) {
            const createCheckout = async () => {
                const configuration = {
                    environment: `${process.env.REACT_APP_ADYEN_ENVIRONMENT}`, // Change to 'live' for the live environment.
                    clientKey: `${process.env.REACT_APP_ADYEN_CLIENT_KEY}`, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
                    session: {
                        id: dropInInfo!.id, // Unique identifier for the payment session.
                        sessionData: dropInInfo!.sessionData // The payment session data.
                    },
                    onPaymentCompleted: (result: any, component: any) => {
                        const resultCode = adyenResultCodes.get(result.resultCode);

                        //console.info({action: "onPaymentCompleted", result, cuddlesResultCode: resultCode});

                        props.onComplete &&
                            props.onComplete(
                                resultCode
                                    ? {
                                          success: resultCode.success,
                                          message: resultCode.actionToTake,
                                          sessionId: dropInInfo!.id,
                                          resultCode: result.resultCode
                                      }
                                    : {
                                          success: false,
                                          message: 'An error as ocurred.',
                                          sessionId: dropInInfo!.id
                                      }
                            );
                    },
                    onError: (error: any, component: any) => {
                        let errorMessage = '';
                        // console.error({ action: 'onError', error });
                        if (error.name === NETWORK_ERROR) {
                            errorMessage = 'Network error!';
                            setError(errorMessage);
                        } else {
                            errorMessage = 'An unexpected error has occured!';
                            setError(errorMessage);
                        }

                        props.onError && props.onError(errorMessage);
                    },
                    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
                    // For example, this is 3D Secure configuration for cards:
                    paymentMethodsConfiguration: {
                        card: {
                            hasHolderName: true,
                            holderNameRequired: false,
                            billingAddressRequired: false
                        }
                    }
                };
                const checkout = await AdyenCheckout(configuration);
                if (dropInRef && dropInRef.current) {
                    checkout
                        .create('dropin', {
                            showStoredPaymentMethods: !(props.hideStoredPaymentMethods ?? false),
                            showRemovePaymentMethodButton: !(props.hideRemovePaymentMethodButton ?? false),
                            onDisableStoredPaymentMethod(storedPaymentMethodId, resolve, reject) {
                                removeDropInPaymentMethod(
                                    apiClientWrapper,
                                    storedPaymentMethodId,
                                    props.invoiceId || props.depositInvoiceId
                                )
                                    .then(result => (result ? resolve() : reject()))
                                    .catch(error => reject);
                            }
                        })
                        .mount(dropInRef.current);
                }
            };

            setLoading(false);
            createCheckout();
        } else if (PaymentsStatus.Error === paymentsStatus) {
            setLoading(false);
            setError('Error getting session.');
        }
    }, [paymentsStatus]);

    return (
        <Box className={clsx(classes.container, props.className)}>
            {loading && <Spinner />}
            {error && <Typography className={classes.error}>{error}</Typography>}
            <div id="dropin-container" ref={dropInRef} className={classes.dropIn}></div>
        </Box>
    );
};

export default AdyenPayment;
