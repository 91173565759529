import { makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { FunctionComponent } from 'react';
import Info from '../Info';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            'fontSize': 16,
            'fontWeight': 500,
            '& strong': {
                fontWeight: 600
            }
        }
    })
);

const DeclinedCardAlert: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Info
            textComponent={
                <Typography className={classes.errorText}>
                    <strong>Your card was declined.</strong> Please try another card or contact support.{' '}
                    <strong>
                        <u>Your current payment method remains active.</u>
                    </strong>
                </Typography>
            }
            icon="alert"
            color="#EF4F57"
            backgroundColor="#FBB9BC33"
            iconColor="#CC444B"
        />
    );
};

export default DeclinedCardAlert;
