import React, { useState } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { CardRow } from './CardRow';
import CreditCardUpdate from './CreditCardUpdate';
import { MarketplacePaymentMethod } from '@spike/payments-model';

interface CardGroupProps {
    cards: Array<MarketplacePaymentMethod>;
    onError: (error: string | undefined) => void;
    onUpdateCardSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            borderRadius: 18,
            [theme.breakpoints.up('sm')]: {
                padding: '6px 32px',
                border: 'solid 1px #DDDDDD'
            }
        }
    })
);

export const CardGroup: React.FunctionComponent<CardGroupProps> = props => {
    const classes = useStyles();

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [activeId, setActiveId] = useState<null | number>(null);
    const [editCard, setEditCard] = useState<MarketplacePaymentMethod | null>(null);

    const openDropdownHandler = (id: number) => {
        if (id === activeId) {
            setActiveId(null);
            return;
        }
        setActiveId(id);
    };

    const editHandler = (card: MarketplacePaymentMethod) => {
        setEditCard(card);
        setShowEditModal(true);
        props.onError && props.onError(undefined);
    };

    const errorHandler = (error: string) => {
        props.onError && props.onError(error);
    };

    const completeHandler = () => {
        setShowEditModal(false);
        props.onUpdateCardSuccess && props.onUpdateCardSuccess();
    };

    return (
        <>
            <Box className={classes.root}>
                {props.cards.map((card, i) => (
                    <CardRow
                        id={i}
                        key={i}
                        card={card}
                        active={false}
                        onEdit={() => {
                            editHandler(card);
                        }}
                        onOpenDropdown={openDropdownHandler}
                    />
                ))}
            </Box>

            {editCard && (
                <CreditCardUpdate
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    onComplete={completeHandler}
                    onError={errorHandler}
                />
            )}
        </>
    );
};
