import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useToggle, useMasterData } from 'hooks';
import { Accordion, Switch } from 'components/UI';
import { MarketplacePetSize, MarketplaceTypeDogs } from '@spike/marketplace-model';
import { Category } from './UI/Category';
import { Ordered, OrderedOption } from '@spike/model';
import { PetTypeStatusState } from 'reducers/businessSettings/PetTypeStatusState';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from './UI/commonStyles';
import PetSizes from './UI/PetSizes';

export interface DogPetTypeProps {
    id?: string;
    dogsType: MarketplaceTypeDogs;
    onChange: (dogsType: MarketplaceTypeDogs) => void;
    petFeatures?: PetTypeStatusState;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordionTitle: {
            'borderBottom': '1px solid #EFEFEF',
            'padding': 0,
            [theme.breakpoints.down('md')]: {
                borderBottom: 'none'
            },
            '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                    fontSize: '6px',
                    paddingBottom: '10px',
                    width: '100%'
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '16px'
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: '16px'
                }
            },

            '&.MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit',
                transform: 'rotate(0deg)',
                [theme.breakpoints.down('md')]: {
                    borderBottom: '1px solid #D4D4D4',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingBottom: '5px'
                }
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                [theme.breakpoints.down('md')]: {
                    margin: '4px 0',
                    marginTop: '12px'
                },
                [theme.breakpoints.only('md')]: {
                    margin: '6px 0'
                },
                [theme.breakpoints.only('lg')]: {
                    margin: '8px 0'
                },
                [theme.breakpoints.only('xl')]: {
                    margin: '12px 0'
                }
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '46px'
            }
        },
        title: {
            fontWeight: 500,
            width: '100%',
            [theme.breakpoints.down('md')]: {
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: '500'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px',
                lineHeight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px',
                lineHeight: '30px'
            }
        },
        boxSpinner: {
            display: 'flex',
            height: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '40px'
            }
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 48,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: 0
            }
        },
        lineDivider: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                borderBottom: 'solid 1px #D4D4D4',
                position: 'relative',
                width: '100%',
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        mobileListStyle: {
            [theme.breakpoints.down('md')]: {
                '& > p': {
                    width: '100% !important',
                    maxWidth: '100% !important'
                },
                '& > div': {
                    width: 'unset'
                }
            }
        },
        categoryContainer: {
            gap: 24
        }
    })
);

export const DogPetType: FunctionComponent<DogPetTypeProps> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const masterData = useMasterData();

    const [open, handleOpen] = useToggle(props.dogsType.enabled);
    const [hairLength, setHairLength] = useState<string[]>([]);

    useEffect(() => {
        const hairLength: string[] = [];
        const sizes: string[] = [];

        props.petFeatures?.pet_hair_length
            .map(x => x.dogs)
            .forEach(d => {
                hairLength.push(d?.short ? 'short' : d?.medium ? 'medium' : d?.long ? 'long' : '');
            });
        props.petFeatures?.pet_size
            .map(x => x.dogs)
            .forEach(d => {
                sizes.push(
                    d?.xsmall
                        ? 'xsmall'
                        : d?.small
                        ? 'small'
                        : d?.medium
                        ? 'medium'
                        : d?.large
                        ? 'large'
                        : d?.xlarge
                        ? 'xlarge'
                        : d?.giant
                        ? 'giant'
                        : ''
                );
            });
        setHairLength(hairLength);
    }, [props.petFeatures]);

    const checkAllHandler = () => {
        props.onChange({ ...props.dogsType, enabled: !open });
        handleOpen();
        /*
        if (!open) {
            props.onChange({
                ages: [...masterData.petAges],
                hairTypes: [...masterData.dogHairTypes],
                hairLengths: [...masterData.dogHairLengths],
                sizes: masterData.petSizes.map(masterDataPetSize => ({
                    element: {
                        ...masterDataPetSize.element
                    },
                    order: masterDataPetSize.order
                }))
            });
        } else {
            props.onChange(undefined);
        }*/
    };

    const changeHanlder = (attribute: 'ages' | 'hairTypes' | 'hairLengths', options: Array<OrderedOption<string>>) => {
        if (props.dogsType) {
            const dogsType = { ...props.dogsType };
            if (attribute === 'ages') {
                dogsType[attribute] = [...options].map(e => e.element);
            } else {
                dogsType[attribute] = [...options];
            }
            props.onChange(dogsType);
        }
    };

    const changeSizesHanlder = (sizes: Array<Ordered<MarketplacePetSize>>) => {
        if (props.dogsType) {
            const dogsType = { ...props.dogsType };
            dogsType.sizes = [...sizes];
            props.onChange(dogsType);
        }
    };

    return (
        <>
            <Accordion
                expanded={open}
                withoutIcon
                handleExpandedChange={checkAllHandler}
                title={'Dogs'}
                titleStyle={classes.title}
                titleContainerStyles={classes.accordionTitle}
                expandComponent={<Switch id={props.id} checked={open} onChange={checkAllHandler} />}
                contentContainerStyles={commonClasses.container}
            >
                <Category
                    title="AGE"
                    masterDataOptions={masterData.petAges.map((e, index) => ({
                        element: e,
                        order: index
                    }))}
                    options={
                        props.dogsType?.ages.map((e, index) => ({
                            element: e,
                            order: index
                        })) || []
                    }
                    onChange={options => {
                        changeHanlder('ages', options);
                    }}
                />
                <Box className={classes.lineDivider}></Box>
                {props.dogsType !== undefined && (
                    <PetSizes
                        masterDataPetSizes={masterData.petSizes}
                        petSizes={props.dogsType?.sizes || []}
                        onChange={sizes => {
                            changeSizesHanlder(sizes);
                        }}
                        requireTwoColumnsOnMobile={true}
                    />
                )}
                <Box className={classes.lineDivider}></Box>
                <Category
                    title="HAIR TYPE"
                    masterDataOptions={[...masterData.dogHairTypes]}
                    options={props.dogsType?.hairTypes || []}
                    onChange={options => {
                        changeHanlder('hairTypes', options);
                    }}
                    requireTwoColumnsOnMobile={true}
                    mobileTextContainerStyle={classes.mobileListStyle}
                />
                <Box className={classes.lineDivider}></Box>
                <Category
                    title="HAIR LENGTH"
                    masterDataOptions={[...masterData.dogHairLengths]}
                    options={props.dogsType?.hairLengths || []}
                    onChange={options => {
                        changeHanlder('hairLengths', options);
                    }}
                    feature={hairLength}
                    requireTwoColumnsOnMobile={false}
                />
            </Accordion>
        </>
    );
};
