import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { FieldError, Option, PetType } from '@spike/model';
import { useMasterData, useMarketplace } from 'hooks';
import { SelectField } from 'components/UI';

interface Props {
    name?: string;
    id?: string;
    selected?: PetType;
    className?: string;
    errors?: Array<FieldError>;
    required?: boolean;
    disabled?: boolean;
    onChange: (petType?: PetType, fieldName?: string) => void;
}

export const PetTypeField: FunctionComponent<Props> = props => {
    const masterData = useMasterData();
    const marketplace = useMarketplace();

    const options: Array<Option<string | number>> = useMemo(
        () =>
            masterData.petTypes
                .filter(pt => marketplace.legacy.petTypes.includes(pt.id) || props.selected?.id === pt.id)
                .map(pt => ({ id: pt.id, name: pt.singular })),
        [props.selected]
    );

    const onSelectHandler = (value: Option<string | number>, fieldName?: string) => {
        const petType = masterData.petTypes.find(pt => pt.id === value.id);
        props.onChange(petType, fieldName);
    };

    return (
        <Box className={clsx(props.className)}>
            <SelectField
                id={props.id}
                label={'Pet Type'}
                placeholder={'Pet Type'}
                selectedOption={
                    props.selected
                        ? {
                              id: props.selected?.id,
                              name: props.selected?.singular
                          }
                        : undefined
                }
                options={options}
                onSelect={onSelectHandler}
                required={props.required}
                errors={props.errors}
                disabled={props.disabled}
                name={props.name}
            />
        </Box>
    );
};

export default PetTypeField;
